import React from 'react';
import useSound from 'use-sound';
import { Link, graphql, navigate } from 'gatsby';

import { Layout, SEO } from 'components';
import { PDFSlideViewer, PDFViewer } from 'components/PDFViewer';
import { download_file } from '../../utils/utils';

import PuffPuffPositive from './../../assets/audio/slope-sister/1)PuffPuffPositive.mp3';
import AhYes from './../../assets/audio/slope-sister/2a)AhhhYeasss.mp3';
import Giggle from './../../assets/audio/slope-sister/2b)giggle.mp3';
import NiceNegative from './../../assets/audio/slope-sister/3)NiceNegative.mp3';
import Giggle2 from './../../assets/audio/slope-sister/3b)Laugh1.mp3';
import ZeroFun from './../../assets/audio/slope-sister/4)ZeroFunOption1.mp3';
import OhOh from './../../assets/audio/slope-sister/5a)OhOhhhh.mp3';
import UhOh from './../../assets/audio/slope-sister/5b)UhOhhh.mp3';
import Undefined from './../../assets/audio/slope-sister/6)Undefined.mp3';
import PlaySoundButton from 'components/PlaySoundButton';
import VideoPlayer from 'components/VideoPlayer';

const VOLUME = 0.5;

/**
 * @param {{
 *    data: {
 *      interactiveLearningPage: {
 *        description: string
 *        title: string
 *        horizontalPdf: boolean
 *        pdfLink?: string
 *        subscriptionOnly: boolean
 *        downloadablePdf: boolean
 *        video?: {
 *          video: {
 *              streamingUrl: string
 *           }
 *        },
 *        videos?: {
 *          video: {
 *            streamingUrl: string
 *          }
 *        }[]
 *        youtubeVideo?: string
 *        paragraphs?: {
 *          paragraph: string
 *          audioClip?: {
 *            url?: string
 *          }
 *          buttonLabel?: string
 *        }[]
 *        seo?: {
 *          title: string
 *          description: string
 *          image?: {
 *              url: string
 *          }
 *        }
 *        seoKeywords?: {
 *          keyword: string
 *        }[],
 *        schemaMarkup: string
 *      }
 *    }
 * }} param
 */
export default function SlopeSister({ data }) {
  const {
    description,
    title,
    pdfLink,
    horizontalPdf,
    paragraphs,
    seo,
    seoKeywords,
    schemaMarkup,
    subscriptionOnly,
    video,
    videos,
    youtubeVideo,
    downloadablePdf,
  } = data.interactiveLearningPage;
  /**
   *  @type {React.MutableRefObject<HTMLCanvasElement> | null} canvasRef
   */
  const canvasRef = React.useRef(null);

  /**
   * @type [HTMLCanvasElement, any]
   */
  const [canvas, setCanvas] = React.useState(null);

  /**
   * @type [CanvasRenderingContext2D, any]
   */
  const [context, setContext] = React.useState(null);

  //Images
  const [skiGirl, setSkiGirl] = React.useState();
  const [skiGirlUp, setSkiGirlUp] = React.useState();
  const [skiGirlDown, setSkiGirlDown] = React.useState();
  const [skiGirlStraightDown, setSkiGirlStraightDown] = React.useState();

  //Audio
  const [play1] = useSound(PuffPuffPositive, { volume: VOLUME });
  const [play2a] = useSound(AhYes, { volume: VOLUME });
  const [play2b] = useSound(Giggle, { volume: VOLUME });
  const [play3a] = useSound(NiceNegative, { volume: VOLUME });
  const [play3b] = useSound(Giggle2, { volume: VOLUME });
  const [play4] = useSound(ZeroFun, { volume: VOLUME });
  const [play5a] = useSound(OhOh, { volume: VOLUME });
  const [play5b] = useSound(UhOh, { volume: VOLUME });
  const [play6] = useSound(Undefined, { volume: VOLUME });

  //Positioning
  const [currX, setCurrX] = React.useState(-10);
  const [currY, setCurrY] = React.useState(-5);

  //Messages
  const [messages, setMessages] = React.useState([]);
  const [speeches, setSpeeches] = React.useState([]);

  //Button state
  const [disabled, setDisabled] = React.useState(false);

  /**
   *
   * @param {HTMLCanvasElement} canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  function drawGridV2(canvas, ctx, start = false, x, y, direction = 'straight') {
    const grid_size = 40;
    const x_axis_distance_grid_lines = 11;
    const y_axis_distance_grid_lines = 11;

    // canvas width
    const canvas_width = canvas.width;

    // canvas height
    const canvas_height = canvas.height;

    ctx.resetTransform();
    ctx.clearRect(0, 0, canvas_width, canvas_height);

    // no of vertical grid lines
    const num_lines_x = Math.floor(canvas_height / grid_size);

    // no of horizontal grid lines
    const num_lines_y = Math.floor(canvas_width / grid_size);

    // Draw grid lines along X-axis
    for (let i = 0; i <= num_lines_x; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;

      // If line represents X-axis draw in different color
      if (i === x_axis_distance_grid_lines) ctx.strokeStyle = '#000000';
      else ctx.strokeStyle = '#e9e9e9';

      ctx.stroke();
    }
    // Draw grid lines along Y-axis
    for (let i = 0; i <= num_lines_y; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;

      // If line represents Y-axis draw in different color
      if (i == y_axis_distance_grid_lines) ctx.strokeStyle = '#000000';
      else ctx.strokeStyle = '#e9e9e9';
      ctx.stroke();
    }
    ctx.translate(
      y_axis_distance_grid_lines * grid_size + 1,
      x_axis_distance_grid_lines * grid_size,
    );

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#000000';

    ctx.moveTo(-10 * grid_size, -3 * grid_size);
    ctx.lineTo(-8 * grid_size, -3 * grid_size);
    ctx.lineTo(-3 * grid_size, -8 * grid_size);
    ctx.lineTo(2 * grid_size, -3 * grid_size);
    ctx.lineTo(7 * grid_size, -3 * grid_size);
    ctx.lineTo(7 * grid_size, 10 * grid_size);
    ctx.stroke();

    drawSkiGirl(ctx, x, y, grid_size, start, direction);
  }

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {number} x
   * @param {number} y
   * @param {number} grid_size
   */
  const drawSkiGirl = (ctx, x, y, grid_size, start = false, direction) => {
    if (!start) {
      const _skiGirl = new Image();
      _skiGirl.src = `${window.location.origin}/skigirl.png`;
      _skiGirl.onload = () => {
        ctx.drawImage(_skiGirl, x * grid_size, (y - 1) * grid_size, grid_size * 3, grid_size * 3);
      };
    } else {
      ctx.drawImage(
        direction === 'up'
          ? skiGirlUp
          : direction === 'straight-down'
          ? skiGirlStraightDown
          : direction === 'down'
          ? skiGirlDown
          : skiGirl,
        x * grid_size,
        (y - 1) * grid_size,
        grid_size * 3,
        grid_size * 3,
      );
    }
  };

  const initialiseGrid = () => {
    drawGridV2(canvas, context, false, -10, -5);
  };

  let curr_x = currX;
  let curr_y = currY;

  const path = [
    {
      coords: [-9.5, -5],
    },
    {
      coords: [-4.5, -10],
      message: {
        message:
          "When she starts out, she's going uphill. It's really hard work. She says 'Puff Puff Positive'.",
        top: '7%',
        left: '-2%',
        rotation: -45,
        shown: false,
      },
      speech: {
        message: 'Puff Puff Positive',
        top: '20%',
        left: '2%',
        rotation: -45,
        shown: false,
      },
      sound: {
        play: play1,
        played: false,
      },
      step: 1 / 128,
    },
    {
      coords: [-4.5, -10],
      message: {
        message:
          "Now she's at the top. It was puff puff positive all the way up, but now she can look forward to the downhill.",
        top: '-15%',
        left: '24%',
        rotation: 0,
        shown: false,
      },
      sound: {
        play: play2a,
        played: false,
      },
      direction: 'staight',
      pauseInSeconds: 3,
    },
    {
      coords: [-4.5, -10],
      sound: {
        play: play2b,
        played: false,
      },
      pauseInSeconds: 2,
    },
    {
      coords: [-0.5, -6],
      message: {
        message: "On the way down, she says 'Nice Nice Negative'",
        top: '8%',
        left: '50%',
        rotation: 45,
        shown: false,
      },
      speech: {
        message: 'Nice Nice Negative',
        top: '15%',
        left: '44%',
        rotation: 45,
        shown: false,
      },
      sound: {
        play: play3a,
        played: false,
      },
      step: 1 / 128,
    },
    {
      coords: [0.5, -5],
      step: 1 / 128,
      sound: {
        play: play3b,
        played: false,
      },
    },
    {
      coords: [4.75, -5],
      message: {
        message:
          "After the downhill, she's in for the easiest part, the long flat part. She says 'This is zero fun'.",
        top: '14%',
        left: '75%',
        shown: false,
      },
      speech: {
        message: 'This is zero fun',
        top: '30%',
        left: '75%',
        shown: false,
      },
      sound: {
        play: play4,
        played: false,
      },
      step: 1 / 128,
    },
    {
      coords: [6.5, -5],
      message: {
        message:
          "As she finishes the flat part, she can't see well ahead and suddenly comes to the edge of a cliff. It's straight down.",
        top: '38%',
        right: '-15%',
        shown: false,
      },
      sound: {
        play: play5a,
        played: false,
      },
    },
    {
      coords: [6.5, -5],
      sound: {
        play: play5b,
        played: false,
      },
      pauseInSeconds: 1.75,
    },
    {
      coords: [6.5, 2],
      message: {
        message:
          "She is so frightened that she says the worst swear word possible in math: 'undefined'",
        top: '70%',
        right: '-15%',
        shown: false,
      },
      speech: {
        message: 'Undefined',
        top: '72%',
        left: '70%',
        rotation: 90,
        shown: false,
      },
      step: 1 / 16,
      sound: {
        play: play6,
        played: false,
      },
    },
  ];

  const wait = (seconds) => new Promise((res) => setTimeout(res, seconds * 1000));

  let direction = 'straight';
  const fast = false;
  let step = 1 / (fast ? 8 : 32);
  const animate = async () => {
    if (path.length) {
      const [destinationX, destinationY] = path[0].coords;
      if ('message' in path[0] && !path[0].message.shown) {
        setMessages((prev) => [...prev, path[0].message]);
        path[0].message.shown = true;
      }

      if ('speech' in path[0] && !path[0].speech.shown) {
        setSpeeches((prev) => [...prev, path[0].speech]);
        path[0].speech.shown = true;
      }

      if ('sound' in path[0] && !path[0].sound.played) {
        path[0].sound.play();
        path[0].sound.played = true;
      }

      if (curr_x !== destinationX) curr_x += (curr_x < destinationX ? 1 : -1) * step;
      if (curr_y !== destinationY) curr_y += (curr_y < destinationY ? 1 : -1) * step;

      drawGridV2(canvas, context, true, curr_x, curr_y, direction);
      if (destinationX === curr_x && destinationY === curr_y) {
        await wait(path[0].pauseInSeconds ?? 0);
        const next_path = path[1] ?? {};
        if (next_path?.coords) {
          step = next_path?.step ?? step;
          const [nextX, nextY] = next_path.coords;
          const new_direction = next_path?.direction;
          direction =
            new_direction ?? curr_y === nextY
              ? 'straight'
              : curr_y < nextY && curr_x === nextX
              ? 'straight-down'
              : curr_y < nextY
              ? 'down'
              : 'up';
        }
        path.shift();
      }
      return requestAnimationFrame(animate);
    }
    setDisabled(false);
  };

  React.useEffect(() => {
    //i.e. value other than null or undefined
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      setCanvas(canvas);
      setContext(ctx);
    }
  }, []);

  React.useEffect(() => {
    setSkiGirl(() => {
      const image = new Image();
      image.src = `${window.location.origin}/skigirl.png`;
      return image;
    });
    setSkiGirlUp(() => {
      const image = new Image();
      image.src = `${window.location.origin}/skigirl_going_up.png`;
      return image;
    });
    setSkiGirlDown(() => {
      const image = new Image();
      image.src = `${window.location.origin}/skigirl_going_down.png`;
      return image;
    });
    setSkiGirlStraightDown(() => {
      const image = new Image();
      image.src = `${window.location.origin}/skigirl_going_down_2.png`;
      return image;
    });
  }, []);

  React.useEffect(() => {
    if (canvas && context) {
      initialiseGrid();
    }
  }, [context, canvas]);

  const startAnimation = () => {
    if (disabled) return;
    setDisabled(true);
    setMessages([]);
    setSpeeches([]);
    requestAnimationFrame(animate);
  };

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  return (
    <Layout>
      <SEO
        description={seo && seo.description}
        image={seo && seo.image}
        title={(seo && seo.title) ?? `Interactive Learning-${title}`}
        seoKeywords={seoKeywords}
        schema={schemaMarkup && JSON.parse(schemaMarkup)}
      />
      <div className="container" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <Link className="brainteaser-breadcrumb" to="/interactive-learning">
          Interactive Learning
        </Link>
        <h1>Slope Sister</h1>
        <p>
          Slope sister adores skiing in the mountains. She goes there every year. What she says
          whilst skiing teaches us about the slope. Slope sister always skis towards the right
          (eastward). Press start to see slope sister go an adventure!
        </p>
        <p className="slope-warning">
          For the best experience, please view this page on desktop. Narration boxes will appear
          underneath the animation on mobile.
        </p>
        <button className="button" disabled={disabled} onClick={startAnimation}>
          Start
        </button>
        <div className="slope-container">
          <div className="canvas-container">
            <div style={{ position: 'relative' }}>
              <canvas ref={canvasRef} id="canvas" width="900" height="600" />
              {speeches.map((speech, i) => (
                <p
                  className="step"
                  style={{
                    top: speech.top,
                    left: speech.left,
                    transform: `rotate(${speech.rotation ?? 0}deg)`,
                  }}
                  key={i}
                >
                  {speech.message}
                </p>
              ))}
              {messages.map((message, i) => (
                <div
                  className="slope-message-abs"
                  style={{
                    top: message.top,
                    ...(message.left && { left: message.left }),
                    ...(message.right && { right: message.right }),
                    transform: `rotate(${message.rotation ?? 0}deg)`,
                  }}
                  key={i}
                >
                  <p>{message.message}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="slope-message-mobile-container">
          {messages.map((message, i) => (
            <div className="slope-message-mobile" key={i}>
              <p>{message.message}</p>
            </div>
          ))}
        </div>
        {paragraphs.length > 0 ? (
          paragraphs.map(({ paragraph, audioClip, buttonLabel }) => (
            <>
              <div dangerouslySetInnerHTML={{ __html: paragraph }} />
              {audioClip?.url && (
                <PlaySoundButton className="my-1" label={buttonLabel} soundUrl={audioClip.url} />
              )}
            </>
          ))
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {allVideos.length ? allVideos.map((src) => <VideoPlayer src={src} />) : null}
        <div className="pb-3">
          {pdfLink && (
            <>
              {downloadablePdf && (
                <button
                  className="button my-1"
                  onClick={() => download_file(pdfLink, 'slope-sister')}
                >
                  Download PDF
                </button>
              )}
              {horizontalPdf ? (
                <PDFSlideViewer url={pdfLink} hideWaterMark={true} />
              ) : (
                <PDFViewer url={pdfLink} hideWaterMark={true} toolbar={false} />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

const query = graphql`
  query SlopeSisterQuery($id: String!) {
    interactiveLearningPage: datoCmsInteractiveLearningPage(originalId: { eq: $id }) {
      title
      pdfLink
      horizontalPdf
      description
      subscriptionOnly
      youtubeVideo
      downloadablePdf
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      paragraphs {
        audioClip {
          url
        }
        paragraph
        buttonLabel
      }
      seo {
        description
        image {
          url
        }
        title
      }
      seoKeywords {
        keyword
      }
      schemaMarkup
    }
  }
`;

export { query };
